import { ref, computed, onMounted, onUnmounted, watch } from 'vue';
import echo from '@/services/pusher';
import { useMessageStore } from '@/stores/messageStore';
import api from '@/utils/api';

export function useOnlineUsers() {
    const messageStore = useMessageStore();
    const errorMessage = ref(null);
    const errorOnAccept = ref(false);

    // Channels
    const userOnlineChannel = ref(null);
    const callDeclineChannel = ref(null);

    // User-related state
    const authuser = ref(null);
    const onlineUsers = ref([]);
    const onlineUsersIds = computed(() =>
        onlineUsers.value.map(user => user.id)
    );

    // Call-related state
    const incomingCall = ref(false);
    const incomingCaller = ref("");
    const agoraChannel = ref("");
    const slotBookingId = ref("");
    const isRescheduled = ref("");
    const userId = ref("");
    const guruId = ref("");
    const userType = ref("");
    const authuserid = ref(null);
    const authusertype = ref("");

    const QuickBookingRequest = ref(false);
    const QuickBookingRequestUserName = ref(null);
    const QuickBookingRequestUserId = ref(null);

    const QuickBookingRequestAccepted = ref(false);

    const QuickBookingRequestRejected = ref(false);
    const callerId = ref(null);
    const AvailableSlotId = ref(null);
    const SelectedTimeSlot = ref(null);
    const lockedSlotsLists = ref([]);
    // Ringing management
    const ringTime = 60000; // 60 seconds
    let ringingTimeout = null;

    // Async function to initialize channels
    async function initChannels() {
        try {
            // Ensure user is authenticated first
            let fetchedUser = await messageStore.currentUser;

            if (fetchedUser == null) {
                fetchedUser = await messageStore.fetchUser();
                console.log(fetchedUser);
            }

            // Update user details
            authuser.value = fetchedUser;
            userType.value = fetchedUser.user_type;
            authuserid.value = fetchedUser.id;
            authusertype.value = fetchedUser.user_type;

            // Leave any existing channel first
            if (userOnlineChannel.value) {
                try {
                    userOnlineChannel.value.leave();
                } catch (leaveError) {
                    console.warn('Error leaving existing channel:', leaveError);
                }
            }

            // Rejoin the channels
            userOnlineChannel.value = echo.join("agora-online-channel");
            callDeclineChannel.value = echo.channel("agora-call-decline-channel");

            // Setup listeners after joining
            setupOnlineUserListeners();
            setupCallDeclineListener();

            // Fetch initial online users
            await fetchInitialOnlineUsers();

            console.log('Channels initialized successfully');
        } catch (error) {
            console.error('Error initializing channels:', error);
        }
    }

    // Fetch initial online users
    async function fetchInitialOnlineUsers() {
        try {
            // Use Pusher's 'here' method to get current online users
            userOnlineChannel.value.here((users) => {
                onlineUsers.value = users;
                console.log('Initial online users:', onlineUsers.value);
            });
        } catch (error) {
            console.error('Error fetching initial online users:', error);
        }
    }

    // Setup online user listeners
    function setupOnlineUserListeners() {
        if (!userOnlineChannel.value) return;

        // Update online users when channel reports current users
        userOnlineChannel.value.here((users) => {
            onlineUsers.value = users;
            console.log('Online users updated (here):', onlineUsers.value);
        });

        // Handle user joining
        userOnlineChannel.value.joining((user) => {
            if (!onlineUsers.value.some((data) => data.id === user.id)) {
                onlineUsers.value.push(user);
                sessionStorage.setItem('onlineUsers', JSON.stringify(onlineUsers.value));
                console.log('User joined:', user);
            }
        });

        // Handle user leaving
        userOnlineChannel.value.leaving((user) => {
            onlineUsers.value = onlineUsers.value.filter((data) => data.id !== user.id);
            sessionStorage.setItem('onlineUsers', JSON.stringify(onlineUsers.value));
            console.log('User left:', user);
        });

        // Listen for incoming calls
        userOnlineChannel.value.listen("MakeAgoraCall", ({ data }) => {
            if (parseInt(data.userToCall) === parseInt(authuserid.value)) {
                const callerIndex = onlineUsers.value.findIndex(
                    (user) => user.id === data.from
                );
                // incomingCaller.value = onlineUsers.value[callerIndex] ? .name || "User";
                incomingCaller.value = onlineUsers.value[callerIndex] ? onlineUsers.value[callerIndex].name : "User";
                incomingCall.value = true;
                agoraChannel.value = data.channelName;
                slotBookingId.value = data.booked_slot_id;
                isRescheduled.value = data.is_rescheduled;
                callerId.value = data.from;
                if (authusertype.value === 'guru') {
                    guruId.value = authuser.value.guru_id;
                    userId.value = data.from;
                } else if (authusertype.value === 'client') {
                    userId.value = authuserid.value;
                    guruId.value = data.from;
                }

                startRingingTimeout();
            }
        });

        userOnlineChannel.value.listen("QuickBookingRequest", ({ data }) => {
            console.log('QuickBookingRequest', data);
            if (data.guru_user_id == authuserid.value) {
                QuickBookingRequest.value = true;
                QuickBookingRequestUserName.value = data.user_name;
                QuickBookingRequestUserId.value = data.user_id;
                console.log('QuickBookingRequestUser', QuickBookingRequestUserName.value);
            }
        });


        userOnlineChannel.value.listen("AcceptQuickRequest", ({ data }) => {
            console.log('Accept Quick Booking', data);
            if (data.user_id == authuserid.value && data.is_accepted == 1) {
                QuickBookingRequestAccepted.value = true;
                console.log('QuickBookingRequestAccepted', QuickBookingRequestAccepted.value);
            } else {
                QuickBookingRequestAccepted.value = false;
                QuickBookingRequestRejected.value = true;
                console.log('QuickBookingRequestAccepted', QuickBookingRequestAccepted.value);
            }
        });

        userOnlineChannel.value.listen("SlotSelected", ({ data }) => {
            // available_slot_id: 20, selected_time: '00:59:31'}
            console.log('SlotSelected', data);
            AvailableSlotId.value = data.available_slot_id;
            SelectedTimeSlot.value = data.selected_time;
            lockedSlotsListsPush(data.available_slot_id);
            console.log('AvailableSlotId', AvailableSlotId.value);

        });
    }

    function lockedSlotsListsPush(available_slot_id) {
        console.log('lockedSlotsLists', available_slot_id);
        lockedSlotsLists.value.push(available_slot_id);
        console.log('lockedSlotsLists', lockedSlotsLists.value);
    }

    async function acceptQuickBookingRequest() {
        QuickBookingRequest.value = false;
        errorOnAccept.value = false;
        try {
            const response = await api.post('/accept-quick-booking-request', {
                user_id: QuickBookingRequestUserId.value,
                is_accepted: 1
            }, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('apiToken')}`
                }
            });

            console.log('Accept Quick Booking', response);
        } catch (error) {
            errorOnAccept.value = true;
            errorMessage.value = error.response.data.message;
            console.error('Error accepting quick booking request:', error);
        }
    }


    function declineQuickBookingRequest() {
        QuickBookingRequest.value = false;
        errorOnAccept.value = false;
        api.post('/accept-quick-booking-request', {
            user_id: QuickBookingRequestUserId.value,
            is_accepted: 0
        }, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('apiToken')}`
            }
        });
    }


    // Setup call decline listener
    function setupCallDeclineListener() {
        if (!callDeclineChannel.value) return;

        callDeclineChannel.value.listen("CallDeclined", (event) => {
            if (event.channelName === agoraChannel.value) {
                stopRinging();
            }
        });
    }

    // Start ringing timeout
    function startRingingTimeout() {
        if (ringingTimeout) {
            clearTimeout(ringingTimeout);
        }
        ringingTimeout = setTimeout(stopRinging, ringTime);
    }

    // Stop ringing and reset call state
    function stopRinging() {
        incomingCall.value = false;
        incomingCaller.value = "";
        agoraChannel.value = "";
        slotBookingId.value = "";
        isRescheduled.value = "";
        userId.value = "";
        guruId.value = "";
    }

    // Accept call method
    function acceptCall() {
        const params = {
            auth_user_id: authuserid.value,
            channel_name: agoraChannel.value,
            booked_slot_id: slotBookingId.value,
            is_rescheduled: isRescheduled.value,
            authusertype: authusertype.value,
            caller_id: callerId.value,
        };
        sessionStorage.setItem('canShowCallPage', true);
        const encodedParams = btoa(JSON.stringify(params));
        window.open(`/call-appointment?accept=${encodedParams}`, '_blank');
        stopRinging();
    }

    // Decline call method
    async function declineCall() {
        try {
            await api.post("agora/call-declined", {
                channel_name: agoraChannel.value,
            }, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('apiToken')}`,
                },
            });
            stopRinging();
        } catch (error) {
            console.error('Error declining call:', error);
        }
    }

    // Fetch online gurus



    // Lifecycle hooks
    onMounted(() => {
        initChannels();
    });

    // Add a watcher to reinitialize if authentication changes
    watch(authuserid, (newUserId) => {
        if (newUserId) {
            initChannels();
        }
    });

    // Clean up on unmount
    onUnmounted(() => {

        if (ringingTimeout) {
            clearTimeout(ringingTimeout);
        }

        // Leave channels on unmount
        if (userOnlineChannel.value) {
            try {
                userOnlineChannel.value.leave();
            } catch (leaveError) {
                console.warn('Error leaving channel on unmount:', leaveError);
            }
        }

        //quick booking datas cleanup
        QuickBookingRequest.value = false;
        QuickBookingRequestUserName.value = null;
        QuickBookingRequestUserId.value = null;
        QuickBookingRequestAccepted.value = false;


    });

    return {
        // Online users
        onlineUsers,
        onlineUsersIds,

        // Call-related states
        incomingCall,
        incomingCaller,
        agoraChannel,
        slotBookingId,
        isRescheduled,
        userId,
        guruId,

        // Methods
        acceptCall,
        declineCall,
        initChannels, // Expose this method for manual reinitialization

        // Quick Booking Request

        QuickBookingRequest,
        QuickBookingRequestUserName,
        QuickBookingRequestUserId,
        acceptQuickBookingRequest,
        declineQuickBookingRequest,
        QuickBookingRequestAccepted,
        QuickBookingRequestRejected,
        lockedSlotsLists,

        errorMessage,
        errorOnAccept,
    };
}