<script setup>
import { useLayout } from "@/layout/composables/layout";
import { ref, computed, onMounted, watch, inject, toRefs } from "vue";
import { useRouter } from "vue-router";
import { useMessageStore } from "@/stores/messageStore";
import api from "@/utils/api";
import { useToast } from "primevue/usetoast";
import Toast from "primevue/toast";
import ConfirmDialog from "primevue/confirmdialog";

import { useInactivityWatcher } from "@/composables/useInactivityWatcher";

import { useConfirm } from "primevue/useconfirm";
const confirm = useConfirm();

const router = useRouter();

import Dialog from "primevue/dialog";
import Button from "primevue/button";
import OverlayBadge from "primevue/overlaybadge";
import Dropdown from "primevue/dropdown";
import ToggleButton from "primevue/togglebutton";
import ToggleSwitch from "primevue/toggleswitch";

const { onMenuToggle } = useLayout();

const displayConfirmation = ref(false);

const messageStore = useMessageStore();

const unreadNotificationsCount = computed(() =>
  messageStore.getUnreadNotificationsCount()
);

const isPasswordNotUpdated = computed(() => messageStore.isPasswordNotUpdated);

import { useI18n } from "vue-i18n";
const { locale, t: ti } = useI18n();

// for go live
const toast = useToast();
const authUserType = ref("");
const authuser = ref("");
const isAvailableToAnyOne = ref(false);

const logout = () => {
  if (isAvailableToAnyOne.value) {
    isAvailableToAnyOne.value = false;
    setAvailability(false).then((response) => {
      sessionStorage.removeItem("apiToken");
      location.href = "/login";
    })
  } else {
    sessionStorage.removeItem("apiToken");
    location.href = "/login";
  }
};

function openConfirmation() {
  console.log("hello from logout confirmation");
  displayConfirmation.value = true;
}

function closeConfirmation() {
  displayConfirmation.value = false;
}

const selectedCountry = ref({
  label: "EN",
  value: "en",
  code: "US",
  flag: "https://primefaces.org/cdn/primevue/images/flag/flag_placeholder.png",
});

const languages = [
  {
    label: "NP",
    value: "np",
    flag: "https://primefaces.org/cdn/primevue/images/flag/flag_placeholder.png",
    code: "NP",
  },
  {
    label: "EN",
    value: "en",
    flag: "https://primefaces.org/cdn/primevue/images/flag/flag_placeholder.png",
    code: "US",
  },
];

function onLanguageChange() {
  localStorage.setItem("language", selectedCountry.value.value);
  locale.value = selectedCountry.value.value;
}

async function fetchProfile() {
  try {
    const response = await api.get("/get-profile", {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("apiToken")}`,
      },
    });
    const data = response.data.result.result;
    isAvailableToAnyOne.value = data?.is_available_to_anyone;
  } catch (error) {
    console.log(error);
  }
}

const isInitialized = ref(false);

onMounted(async () => {
  authuser.value = await messageStore.currentUser;
  if (authuser.value == null) {
    authuser.value = await messageStore.fetchUser();
  }
  console.log("authuser.value");
  console.log(authuser.value);

  isAvailableToAnyOne.value = authuser.value?.is_available_to_anyone;
  authUserType.value = authuser.value.user_type;

  const lang = localStorage.getItem("language");
  if (lang === "np") {
    selectedCountry.value = languages[0];
    locale.value = "np";
  } else {
    selectedCountry.value = languages[1];
    locale.value = "en";
  }

  checkIsGuruActive();
});

watch(isAvailableToAnyOne, (newValue) => {
  if (!isInitialized.value) {
    return;
  }

  console.log(isAvailableToAnyOne.value);

  if (authUserType.value !== "guru") {
    return;
  }

  setAvailability(newValue)
    .then((response) => {
      console.log(response);
    })
    .catch((error) => {
      console.log(error);
      showError("Failed to update availability");
    });

  checkIsGuruActive();
});

async function setAvailability(newValue) {
  try {
    const response= await api
      .post(
        "/set-available-to-anyone",
        {
          is_available_to_anyone: newValue,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("apiToken")}`,
          },
        }
      );
    console.log(response);
  } catch(error) {
    console.log(error);
    showError("Failed to update availability");
  }
}

function checkIsGuruActive() {
  if (!isAvailableToAnyOne.value) {
    return;
  }

  const { isGuruActive } = useInactivityWatcher(1 * 60 * 1000);
  console.log("isGuruActive.value");
  console.log(isGuruActive.value);

  if (!isGuruActive.value) {
    console.log("isGuruActive.value false condition");
    console.log(isGuruActive.value);
    isAvailableToAnyOne.value = false;
  }
}

function showSuccess(message) {
  toast.add({
    severity: "success",
    summary: "Success Message",
    detail: message,
    life: 3000,
  });
}

function showError(message) {
  toast.add({
    severity: "error",
    summary: "Error Message",
    detail: message,
    life: 3000,
  });
}

function goLive(value) {
  isInitialized.value = true;
  confirm.require({
    group: "headless",
    header: "Are you sure?",
    message: "Are you sure you want to change your availability.",
    accept: () => {
      isAvailableToAnyOne.value = !isAvailableToAnyOne.value;
    },
  });
}

const isMenuOpen = ref(false);

const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value;
};
</script>

<template>
  <Toast />
  <div class="layout-topbar">
    <div class="layout-topbar-logo-container">
      <button
        class="layout-menu-button layout-topbar-action"
        @click="onMenuToggle"
      >
        <i class="pi pi-bars"></i>
      </button>
      <router-link to="/" class="layout-topbar-logo">
        <img
          src="https://i.ibb.co/02nRRLN/Whats-App-Image-2024-12-23-at-17-58-42-d6cb4847-removebg-preview.png"
          alt="logo"
          class="logo-img"
        />
      </router-link>
    </div>

    <!-- warning -->
    <div
      class="bg-orange-100 rounded-lg text-gray-500 p-2 px-4 hidden sm:block password-change-warning"
      v-if="isPasswordNotUpdated"
    >
      <span>{{ ti("topbar.change_password_warning") }}</span>
      <a
        class="ml-1 text-orange-600 cursor-pointer"
        @click="router.push('/password')"
      >
        {{ ti("topbar.change_password") }} <i class="pi pi-arrow-right"></i
      ></a>
    </div>

    <div class="layout-topbar-actions">
      <div
        v-if="authUserType === 'guru'"
        class="layout-topbar-actions-container flex items-center" 
      >
        <Button
          severity="secondary"
          :label="isAvailableToAnyOne == 0 ? 'Off' : 'On'"
          class="ml-4"
          @click="goLive"
          v-tooltip.bottom="isAvailableToAnyOne ? 'Go offline' : 'Go live'"
        />
        <p v-if="isAvailableToAnyOne == 1" class="text-green-500 ml-4">
          <i class="pi pi-circle-fill"></i>
        </p>
      </div>

      <Dropdown
        v-model="selectedCountry"
        :options="languages"
        optionLabel="label"
        class="md:w-21"
        placeholder=""
        @change="onLanguageChange"
        v-tooltip.bottom="'Language'"
      >
        <template #option="slotProps">
          <div class="flex items-center">
            <img
              :src="slotProps.option.flag"
              :class="`flag flag-${slotProps.option.code?.toLowerCase()}`"
              style="width: 2rem"
            />
          </div>
        </template>

        <template #value="slotProps">
          <div class="flex items-center" v-if="slotProps.value">
            <img
              v-if="slotProps.value"
              :src="slotProps.value.flag"
              :class="`flag flag-${slotProps.value.code?.toLowerCase()}`"
              class="flag-img"
            />
          </div>
        </template>
      </Dropdown>

      <button
        class="layout-topbar-menu-button layout-topbar-action"
        v-styleclass="{
          selector: '@next',
          enterFromClass: 'hidden',
          enterActiveClass: 'animate-scalein',
          leaveToClass: 'hidden',
          leaveActiveClass: 'animate-fadeout',
          hideOnOutsideClick: true,
        }"
        @click="toggleMenu"
      >
        <i class="pi pi-ellipsis-v"></i>
      </button>

      <!-- <div class="layout-topbar-menu hidden lg:block"> -->
      <div 
      class="layout-topbar-menu"
      :class="{'hidden': !isMenuOpen, 'block': isMenuOpen, 'lg:block': true}"
      >
        <div class="layout-topbar-menu-content">
          <OverlayBadge
            v-if="unreadNotificationsCount > 0"
            :value="unreadNotificationsCount"
            severity="danger"
          >
            <button
              type="button"
              class="layout-topbar-action"
              @click="router.push('/notifications')"
            >
              <i class="pi pi-bell" />
              <span>Notification</span>
            </button>
          </OverlayBadge>
          <button
            v-else
            type="button"
            class="layout-topbar-action"
            @click="router.push('/notifications')"
            v-tooltip.bottom="'Notifications'"
          >
            <i class="pi pi-bell" />
            <span>Notification</span>
          </button>
          <router-link
            to="/profile"
            class="layout-topbar-action"
            v-tooltip.bottom="'Profile'"
          >
            <i class="pi pi-user"></i>
            <span>Profile</span>
          </router-link>
          <button
            type="button"
            class="layout-topbar-action"
            @click="openConfirmation()"
            v-tooltip.bottom="'Logout'"
          >
            <i class="pi pi-sign-out"></i>
            <span>Logout</span>
          </button>
        </div>
      </div>
      <Dialog
        :header="ti('app_top_bar.confirmation')"
        v-model:visible="displayConfirmation"
        :style="{ width: '350px' }"
        :modal="true"
      >
        <div class="flex items-center justify-center">
          <i class="pi pi-exclamation-triangle mr-4" style="font-size: 2rem" />
          <span>{{ ti("app_top_bar.are_you_sure_to_logout") }}</span>
        </div>
        <template #footer>
          <Button
            :label="ti('app_top_bar.no')"
            icon="pi pi-times"
            @click="closeConfirmation"
            text
            severity="secondary"
          />
          <Button
            :label="ti('app_top_bar.yes')"
            icon="pi pi-check"
            @click="logout()"
            severity="danger"
            outlined
            autofocus
          />
        </template>
      </Dialog>
    </div>
  </div>

  <ConfirmDialog group="headless">
    <template #container="{ message, acceptCallback, rejectCallback }">
      <div
        class="flex flex-col items-center p-8 bg-surface-0 dark:bg-surface-900 rounded"
      >
        <div
          class="rounded-full bg-primary text-primary-contrast inline-flex justify-center items-center h-24 w-24 -mt-20"
        >
          <i class="pi pi-question text-5xl"></i>
        </div>
        <span class="font-bold text-2xl block mb-2 mt-6">{{
          message.header
        }}</span>
        <p class="mb-0">{{ message.message }}</p>
        <div class="flex items-center gap-2 mt-6">
          <Button label="Yes" @click="acceptCallback"></Button>
          <Button label="No" outlined @click="rejectCallback"></Button>
        </div>
      </div>
    </template>
  </ConfirmDialog>
</template>

<style scoped>
  .p-tooltip {
    color: brown;
    white-space: nowrap;
    transform: translateX(-50%);
  }

  .flag-img {
    width: 2rem; /* Default size for larger screens */
  }

  /* Tablet screens */
  @media (max-width: 1024px) {
    .flag-img {
      width: 2.5rem;
    }
  }

  /* Mobile screens */
  @media (max-width: 768px) {
    .flag-img {
      width: 2rem;
    }
  }

  /* Smaller mobile screens */
  @media (max-width: 480px) {
    .flag-img {
      width: 2rem;
    }
  }


  .logo-img {
    width: 100px;
  }

  @media (max-width: 1024px) {
    .logo-img {
      width: 80px;
    }
  }

  @media (max-width: 570px) {
    .logo-img {
      width: 100px;
    }
  }
  
</style>
