<script setup>
import api from "@/utils/api";
import { onMounted, ref } from 'vue';
import AppMenuItem from './AppMenuItem.vue';

// import { useI18n } from 'vue-i18n';
// const { t: ti, locale } = useI18n();

const isLoading = ref(true);
// Holds the full menu model with improved icons
const fullModel = ref([
  {
    label: 'sidebar.general',
    userType: 'admin',
    items: [
      { label: 'sidebar.dashboard', icon: 'pi pi-fw pi-th-large', to: '/dashboard' },
      { label: 'sidebar.gurus', icon: 'pi pi-fw pi-user-plus', to: '/admin/gurus' },
      { label: 'sidebar.users', icon: 'pi pi-fw pi-users', to: '/admin/users' },
      { label: 'sidebar.refund-request', icon: 'pi pi-fw pi-wallet', to: '/admin/refund-request' },
      { label: 'sidebar.horoscope', icon: 'pi pi-fw pi-star', to: '/admin/horoscope' },
      { label: 'sidebar.reviews', icon: 'pi pi-fw pi-star-fill', to: '/admin/reviews' },
      { label: 'sidebar.bookings', icon: 'pi pi-fw pi-calendar-plus', to: '/admin/bookings' },
      { label: 'sidebar.payments', icon: 'pi pi-fw pi-credit-card', to: '/admin/payments' },
      { label: 'sidebar.blogs', icon: 'pi pi-fw pi-file-edit', to: '/blogs' },
    ]
  },

  {
    label: 'sidebar.general',
    userType: 'guru',
    items: [
      { label: 'sidebar.dashboard', icon: 'pi pi-fw pi-th-large', to: '/dashboard' },
      { label: 'sidebar.slots', icon: 'pi pi-fw pi-calendar-times', to: '/guru/slots' },
      { label: 'sidebar.client-bookings', icon: 'pi pi-fw pi-users', to: '/guru/clients' },
    ]
  },

  {
    label: 'sidebar.general',
    userType: 'client',
    items: [
      { label: 'sidebar.dashboard', icon: 'pi pi-fw pi-th-large', to: '/dashboard' },
      { label: 'sidebar.browse-gurus', image: require('@/assets/images/guru.png'), to: '/gurus' },
      { label: 'sidebar.quick-bookings', icon: 'pi pi-fw pi-bolt', to: '/quick-bookings' },
      { label: 'sidebar.my-bookings', icon: 'pi pi-fw pi-calendar', to: '/my-bookings' },
      { label: 'sidebar.my-payments', icon: 'pi pi-fw pi-money-bill', to: '/my-payments' },
      { label: 'sidebar.horoscope', icon: 'pi pi-fw pi-star', to: '/horoscope' },
    ]
  },

  {
    label: 'sidebar.profile-and-security',
    userType: 'admin',
    items: [
      {
        label: 'sidebar.settings',
        icon: 'pi pi-fw pi-cog',
        items: [
          {
            label: 'sidebar.profile',
            icon: 'pi pi-fw pi-user',
            to: '/profile'
          },
          {
            label: 'sidebar.password',
            icon: 'pi pi-fw pi-lock',
            to: '/password'
          },
        ],
      },
    ],
  },

  {
    label: 'sidebar.profile-and-security',
    userType: 'guru',
    items: [
      {
        label: 'sidebar.settings',
        icon: 'pi pi-fw pi-cog',
        items: [
          {
            label: 'sidebar.profile',
            icon: 'pi pi-fw pi-user',
            to: '/profile'
          },
          {
            label: 'sidebar.password',
            icon: 'pi pi-fw pi-lock',
            to: '/password'
          },
          {
            label: 'sidebar.delete_account',
            icon: 'pi pi-fw pi-trash',
            to: '/delete-account'
          },
        ],
      },
    ],
  },

  {
    label: 'sidebar.profile-and-security',
    userType: 'client',
    items: [
      {
        label: 'sidebar.settings',
        icon: 'pi pi-fw pi-cog',
        items: [
          {
            label: 'sidebar.profile',
            icon: 'pi pi-fw pi-user',
            to: '/profile'
          },
          {
            label: 'sidebar.password',
            icon: 'pi pi-fw pi-lock',
            to: '/password'
          },
          {
            label: 'sidebar.delete_account',
            icon: 'pi pi-fw pi-trash',
            to: '/delete-account'
          },
        ],
      },
    ],
  },
]);

const model = ref([]);

onMounted(async () => {
  try {
    if (!sessionStorage.getItem('apiToken')) {
      return;
    }
    const response = await api.get('/get-profile', {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('apiToken')}`
      }
    });
    const userType = response.data.result.result.user_type;

    // Set filtered model
    model.value = fullModel.value.filter(
        section => section.userType === userType || section.userType === 'all'
    );

    // Add staggered loading animation by delaying the isLoading change
    setTimeout(() => {
      isLoading.value = false;
    }, 500);
  } catch (error) {
    console.error('Failed to fetch user profile:', error);
    isLoading.value = false;
  }
});
</script>

<template>
  <!-- Main menu with animation classes -->
  <ul class="layout-menu animate-menu" v-if="!isLoading">
    <template v-for="(item, i) in model" :key="item.label">
      <app-menu-item v-if="!item.separator" :item="item" :index="i"></app-menu-item>
      <li v-if="item.separator" class="menu-separator"></li>
    </template>
  </ul>

  <!-- Loading skeleton with improved animation -->
  <ul class="layout-menu" v-if="isLoading">
    <li class="menu-item">
      <div class="menu-item-content skeleton-item" v-for="n in 6" :key="n">
        <div class="menu-icon">
          <i class="pi pi-spin pi-spinner"></i>
        </div>
        <div class="menu-text skeleton-text">
          <span class="menu-text-span"></span>
        </div>
      </div>
    </li>
  </ul>
</template>

<style scoped>
/* Base styles from original */
.menu-item-content {
  display: flex;
  align-items: center;
  padding: 1rem;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.menu-icon {
  margin-right: 1rem;
  transition: transform 0.3s ease, color 0.3s ease;
}

.menu-text {
  display: inline-block;
  width: 100px;
  height: 1rem;
}

.menu-text-span {
  background-color: #f0f0f0;
  height: 100%;
  width: 100%;
  display: block;
}

/* Animation for menu appearance */
.animate-menu :deep(.menu-item) {
  animation: fadeSlideIn 0.5s ease forwards;
  opacity: 0;
}

/* Add staggered delay for each item */
.animate-menu :deep(.menu-item:nth-child(1)) { animation-delay: 0.05s; }
.animate-menu :deep(.menu-item:nth-child(2)) { animation-delay: 0.1s; }
.animate-menu :deep(.menu-item:nth-child(3)) { animation-delay: 0.15s; }
.animate-menu :deep(.menu-item:nth-child(4)) { animation-delay: 0.2s; }
.animate-menu :deep(.menu-item:nth-child(5)) { animation-delay: 0.25s; }
.animate-menu :deep(.menu-item:nth-child(6)) { animation-delay: 0.3s; }
.animate-menu :deep(.menu-item:nth-child(7)) { animation-delay: 0.35s; }
.animate-menu :deep(.menu-item:nth-child(8)) { animation-delay: 0.4s; }
.animate-menu :deep(.menu-item:nth-child(9)) { animation-delay: 0.45s; }

/* Animation for hover effects */
.animate-menu :deep(.menu-item-content:hover) {
  background-color: rgba(0, 0, 0, 0.04);
  transform: translateX(5px);
}

.animate-menu :deep(.menu-item-content:hover .menu-icon) {
  transform: scale(1.2);
  color: #4e54c8;
}

/* Animation for active menu item */
.animate-menu :deep(.router-link-active .menu-item-content) {
  background-color: rgba(78, 84, 200, 0.1);
}

.animate-menu :deep(.router-link-active .menu-item-content::before) {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 4px;
  background: linear-gradient(to bottom, #4e54c8, #8f94fb);
  animation: slideIn 0.3s ease forwards;
}

.animate-menu :deep(.router-link-active .menu-icon) {
  color: #4e54c8;
}

/* Skeleton loading animation */
.skeleton-item {
  position: relative;
  overflow: hidden;
}

.skeleton-text {
  background-color: #f0f0f0;
  position: relative;
  overflow: hidden;
}

.skeleton-text::after, .skeleton-item::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(90deg,
  rgba(255,255,255,0) 0%,
  rgba(255,255,255,0.6) 50%,
  rgba(255,255,255,0) 100%);
  animation: shimmer 1.5s infinite;
}

/* Menu separator */
.menu-separator {
  height: 1px;
  background-color: #f0f0f0;
  margin: 0.5rem 0;
}

/* Spinner animation */
@keyframes spin {
  to { transform: rotate(360deg); }
}

.pi-spin {
  animation: spin 0.8s linear infinite;
}

/* Fade slide in animation */
@keyframes fadeSlideIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Shimmer effect for skeleton */
@keyframes shimmer {
  from { transform: translateX(-100%); }
  to { transform: translateX(100%); }
}

/* Slide in animation for active indicator */
@keyframes slideIn {
  from { transform: translateX(-100%); }
  to { transform: translateX(0); }
}
</style>
