import { ref, onMounted, onUnmounted } from 'vue';

export function useInactivityWatcher(inactivityTime = 1 * 60 * 1000) {
    const isGuruActive = ref(true);
    let inactivityTimer;

    const resetInactivityTimer = () => {
        console.log('resetInactivityTimer');
        clearTimeout(inactivityTimer);
        inactivityTimer = setTimeout(() => {
            console.log('timeout');
            isGuruActive.value = false;
        }, inactivityTime);
    };

    resetInactivityTimer();

    onMounted(() => {
        window.addEventListener('mousemove', resetInactivityTimer);
        window.addEventListener('keypress', resetInactivityTimer);
        window.addEventListener('scroll', resetInactivityTimer);
        window.addEventListener('click', resetInactivityTimer);
    });

    onUnmounted(() => {
        window.addEventListener('mousemove', resetInactivityTimer);
        window.addEventListener('keypress', resetInactivityTimer);
        window.addEventListener('scroll', resetInactivityTimer);
        window.addEventListener('click', resetInactivityTimer);
    });

    return { isGuruActive }

}