import AppLayout from '@/layout/AppLayout.vue';
import api from "@/utils/api";

import {createRouter, createWebHistory} from 'vue-router';

// let userType = null;

// document.addEventListener('DOMContentLoaded', async function() {
//     console.log('affas');
//     const sessionId = sessionStorage.getItem('apiToken');
//     console.log(sessionId);
//     if (sessionId) {
//         console.log('sessionId inside');
//         await fetchProfile();
//     }
// });

async function fetchProfile() {
    const response = await api.get('/get-profile', {
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem('apiToken')}`
        }
    });
    const data = response.data.result.result;
    userType = data.user_type;
}

const router = createRouter({
    history: createWebHistory(),
    routes: [{
        path: '/dashboard',
        component: AppLayout,
        children: [{
            path: '/dashboard',
            name: 'dashboard',
            component: () =>
                import ('@/views/Dashboard.vue'),
            meta: {requiresAuth: true, title: 'Dashboard'}
        },
            {
                path: '/caller-list',
                name: 'caller-list',
                component: () =>
                    import ('@/views/pages/call/CallerList.vue'),
                meta: {requiresAuth: true, title: 'Caller List'}
            },
            // admin routes
            {
                path: '/admin/gurus',
                name: 'guru.index',
                component: () =>
                    import ('@/views/pages/admin/guru/GurusList.vue'),
                meta: {requiresAuth: true, userType: 'admin', title: 'Admin - Gurus'}
            },
            {
                path: '/admin/gurus/:id/details',
                name: 'guru.details',
                component: () =>
                    import ('@/views/pages/admin/guru/GurusDetails.vue'),
                meta: {requiresAuth: true, userType: 'admin', title: 'Admin - Gurus'}
            },
            {
                path: '/admin/users',
                name: 'user.index',
                component: () =>
                    import ('@/views/pages/admin/users/UsersList.vue'),
                meta: {requiresAuth: true, userType: 'admin', title: 'Admin - Users'}
            },
            {
                path: '/admin/users/:id/details',
                name: 'user.details',
                component: () =>
                    import ('@/views/pages/admin/guru/UsersDetails.vue'),
                meta: {requiresAuth: true, userType: 'admin', title: 'Admin - Users'}
            },
            {
                path: '/admin/reviews',
                name: 'review.index',
                component: () =>
                    import ('@/views/pages/admin/guru/ReviewsList.vue'),
                meta: {requiresAuth: true, userType: 'admin', title: 'Admin - Reviews'}
            },
            {
                path: '/admin/bookings',
                name: 'admin.bookings',
                component: () =>
                    import ('@/views/pages/admin/guru/BookingsList.vue'),
                meta: {requiresAuth: true, userType: 'admin', title: 'Admin - Bookings'}
            },
            {
                path: '/admin/booking/details/:id',
                name: 'admin.bookings.details',
                component: () =>
                    import ('@/views/pages/admin/guru/BookingDetails.vue'),
                meta: {requiresAuth: true, userType: 'admin', title: 'Admin - Booking Details'}
            },
            // guru routes
            {
                path: '/guru/slots',
                name: 'slot.index',
                component: () =>
                    import ('@/views/pages/guru/slots/SlotsPage.vue'),
                meta: {requiresAuth: true, userType: 'guru', title: 'Guru - Slots'}
            },
            {
                path: '/guru/clients',
                name: 'client.index',
                component: () =>
                    import ('@/views/pages/call/CallerList.vue'),
                meta: {requiresAuth: true, userType: 'guru', title: 'Guru - Clients'}
            },
            {
                path: '/call',
                name: 'call',
                component: () =>
                    import ('@/views/pages/Call.vue'),
                meta: {requiresAuth: true, title: 'Call'}
            },
            // client routes
            {
                path: '/gurus',
                name: 'gurus.index',
                component: () =>
                    import ('@/views/pages/client/gurus/GuruList.vue'),
                meta: {requiresAuth: true, userType: 'client', title: 'Gurus List'}
            },
            {
                path: '/guru/:id',
                name: 'gurus.show',
                component: () =>
                    import ('@/views/pages/client/gurus/GuruDetail.vue'),
                meta: {requiresAuth: true, userType: 'client', title: 'Guru Details'}
            },
            {
                path: '/my-bookings',
                name: 'bookings.index',
                component: () =>
                    import ('@/views/pages/call/CallerList.vue'),
                meta: {requiresAuth: true, userType: 'client', title: 'My Bookings'}
            },
            {
                path: '/my-payments',
                name: 'payments.index',
                component: () =>
                    import ('@/views/pages/PaymentsList.vue'),
                meta: {requiresAuth: true, userType: 'client', title: 'My Payments'}
            },
            {
                path: '/quick-bookings',
                name: 'quick-bookings',
                component: () =>
                    import ('@/views/pages/client/QuickBooking.vue'),
                meta: {requiresAuth: true, userType: 'client', title: 'Quick Bookings'}
            },
            {
                path: '/profile',
                name: 'profile',
                component: () =>
                    import ('@/views/pages/auth/ProfilePage.vue'),
                meta: {requiresAuth: true, title: 'Profile'}
            },
            {
                path: '/password',
                name: 'password',
                component: () =>
                    import ('@/views/pages/auth/PasswordPage.vue'),
                meta: {requiresAuth: true, title: 'Change Password'}
            },
            {
                path: '/delete-account',
                name: 'delete-account',
                component: () =>
                    import ('@/views/pages/auth/DeleteAccount.vue'),
                meta: {
                    requiresAuth: true,
                    userType: ['client', 'guru'],
                    title: 'Delete Account'
                }
            },
            {
                path: '/delete-account/confirm',
                name: 'delete-account-confirm',
                component: () =>
                    import ('@/views/pages/auth/DeleteAccountConfirm.vue'),
                meta: {
                    requiresAuth: true,
                    userType: ['client', 'guru'],
                    title: 'Delete Account Confirm'
                }
            },
            {
                path: '/notifications',
                name: 'notifications',
                component: () =>
                    import ('@/views/pages/NotificationView.vue'),
                meta: {title: 'Notifications'}
            },
            {
                path: '/admin/payments',
                name: 'payments',
                component: () =>
                    import ('@/views/pages/admin/PaymentPage.vue'),
                meta: {requiresAuth: true, userType: 'admin', title: 'Payments'}
            },
            {
                path: '/admin/refund-request',
                name: 'refund-request',
                component: () =>
                    import ('@/views/pages/RefundRequest.vue'),
                meta: {requiresAuth: true, userType: 'admin', title: 'Refund Request'}
            },
            {
                path: '/guru/:id/reschedule/:slotBookingIdForReschedule',
                name: 'gurus.reschedule',
                component: () =>
                    import ('@/views/pages/client/gurus/Reschedule.vue'),
                meta: {requiresAuth: true, userType: 'client', title: 'Reschedule Appointment'},
                // beforeEnter: (to, from, next) => {
                //     if (to.query.cancel_and_reschedule) {
                //         next();
                //     } else {
                //         next({ name: 'some.error.route' });
                //     }
                // }
            },
            {
                path: '/guru/:id/cancel-and-reschedule/:slotBookingIdForReschedule',
                name: 'gurus.cancel-and-reschedule',
                component: () =>
                    import ('@/views/pages/client/gurus/CancelAndReschedule.vue'),
                meta: {requiresAuth: true, userType: 'client', title: 'Cancel and Reschedule Appointment'}
            },
            {
                path: '/admin/horoscope',
                name: 'admin.horoscope',
                component: () =>
                    import ('@/views/pages/admin/ZodiacHoroscope.vue'),
                meta: {requiresAuth: true, userType: 'admin', title: 'Admin Horoscope'}
            },
            {
                path: '/horoscope',
                name: 'horoscope',
                component: () =>
                    import ('@/views/pages/Horoscope.vue'),
                meta: {requiresAuth: true, userType: 'client', title: 'Horoscope'}
            },
            {
                path: '/blogs',
                name: 'blog.index',
                component: () =>
                    import ('@/views/pages/admin/Blogs.vue'),
                meta: {requiresAuth: true, userType: 'admin', title: 'Admin - Blogs'}
            },
            {
                path: '/blogs/create',
                name: 'blog.create',
                component: () =>
                    import ('@/views/pages/admin/CreateBlog.vue'),
                meta: {requiresAuth: true, userType: 'admin', title: 'Admin - Create - Blogs'}
            },
            {
                path: '/blogs/:slug/edit',
                name: 'blog.edit',
                component: () =>
                    import ('@/views/pages/admin/EditBlog.vue'),
                meta: {requiresAuth: true, userType: 'admin', title: 'Admin - Edit - Blogs'}
            },
        ]
    },

            {
                path: '/',
                name: 'home',
                component: () => import('@/views/pages/HomePage.vue'),
                meta: {
                    title: 'ZyotisGuru | #1 Jyotish & Astrology Services in Nepal',
                    description: "Nepal's premier Zyotis Guru platform offering expert jyotish consultations, horoscopes & birth chart analysis. Connect with verified astrologers for accurate predictions & spiritual guidance. 2000+ satisfied clients trust our services.",
                    keywords: "zyotis guru, jyotis, jyotish, jyotis gurus, zyotis, astrologers nepal, astrology nepal, nepal astrology, nepali jyotish, online astrologer nepal, kundali milan, birth chart analysis, horoscope nepal, vedic astrology nepal, best astrologers in nepal",
                    ogTitle: "ZyotisGuru - Nepal's #1 Astrology & Jyotish Service Platform",
                    ogDescription: "Connect with Nepal's best zyotis experts for accurate predictions and spiritual guidance. Trusted by 2000+ clients.",
                    twitterTitle: "ZyotisGuru - #1 Jyotish Platform in Nepal",
                    twitterDescription: "Expert zyotis consultations, accurate predictions & spiritual guidance from verified astrologers."
                }
            },
            {
                path: '/book-appointment',
                name: 'book-appointment',
                component: () => import('@/views/pages/BookAppointment.vue'),
                meta: {
                    title: 'Book Jyotish Consultation | ZyotisGuru',
                    description: "Schedule personalized consultations with ZyotisGuru's expert astrologers. Get accurate predictions and spiritual guidance from Nepal's top-rated zyotis specialists.",
                    keywords: "book zyotis consultation, jyotish appointment, astro reading, zyotisguru booking, online astrologer appointment, nepal astrologer booking, kundali consultation",
                    ogTitle: "Book Your Jyotish Consultation with ZyotisGuru Experts",
                    ogDescription: "Schedule online or in-person consultations with Nepal's top zyotis specialists for accurate predictions.",
                    twitterTitle: "Book ZyotisGuru Consultation",
                    twitterDescription: "Schedule your personal zyotis consultation with Nepal's best astrologers."
                }
            },
            {
                path: '/guru/:id/book-appointment',
                name: 'guru-book-appointment',
                component: () => import('@/views/pages/GuruPage.vue'),
                meta: {
                    title: 'Book Expert Zyotis Consultation | ZyotisGuru',
                    description: "Book personalized consultation with ZyotisGuru's verified astrology experts. Get accurate predictions from Nepal's most experienced jyotish consultants.",
                    keywords: "zyotis guru booking, jyotish expert consultation, astro consultation nepal, book zyotis reading, personal astrologer, vedic astrology consultation",
                    ogTitle: "Book Your Personal Consultation with ZyotisGuru Expert",
                    ogDescription: "Schedule time with Nepal's top-rated zyotis practitioners for personalized guidance.",
                    twitterTitle: "Expert Zyotis Consultation | ZyotisGuru",
                    twitterDescription: "Connect with Nepal's best astrologers for accurate predictions and guidance."
                }
            },
            {
                path: '/astrologers',
                name: 'astrologers',
                component: () => import('@/views/pages/GuruListPage.vue'),
                meta: {
                    title: 'Top Jyotish Experts & Astrologers | ZyotisGuru',
                    description: "Connect with Nepal's best zyotis experts and astrologers. Find verified practitioners with expertise in birth chart analysis, kundali milan, and personalized predictions.",
                    keywords: "zyotis experts, nepal astrologers, jyotish specialists, zyotisguru practitioners, vedic astrology experts, best zyotis in nepal, astro consultants",
                    ogTitle: "Meet ZyotisGuru's Expert Astrologers & Jyotish Specialists",
                    ogDescription: "Browse profiles of Nepal's most trusted and experienced zyotis practitioners.",
                    twitterTitle: "ZyotisGuru's Expert Astrologers",
                    twitterDescription: "Find the perfect zyotis expert for your specific astrological needs."
                }
            },
            {
                path: '/shop',
                name: 'shop',
                component: () => import('@/views/pages/Shop.vue'),
                meta: {
                    title: 'Astrology & Jyotish Products Shop | ZyotisGuru',
                    description: "Browse ZyotisGuru's collection of authentic jyotish and astrology products. Gemstones, pooja items, spiritual artifacts, and ritual materials recommended by expert astrologers.",
                    keywords: "zyotis products, jyotish items, astrology shop nepal, astro gemstones, spiritual products, pooja items, zyotisguru shop",
                    ogTitle: "ZyotisGuru Astrology Shop - Authentic Jyotish Products",
                    ogDescription: "Authentic spiritual products and materials recommended by Nepal's expert zyotis practitioners.",
                    twitterTitle: "ZyotisGuru Astrology Shop",
                    twitterDescription: "Quality jyotish products and spiritual items for your astrological needs."
                }
            },
            {
                path: '/book-puja',
                name: 'book-puja',
                component: () => import('@/views/pages/BookPuja.vue'),
                meta: {
                    title: 'Book Sacred Pujas & Rituals | ZyotisGuru',
                    description: "Book authentic Vedic rituals and pujas performed by ZyotisGuru's experienced priests and astrologers. Remedial ceremonies based on jyotish recommendations.",
                    keywords: "book puja online, jyotish rituals, zyotis remedies, vedic ceremonies, astro rituals, zyotisguru puja, online puja booking nepal",
                    ogTitle: "Book Sacred Pujas & Vedic Rituals with ZyotisGuru",
                    ogDescription: "Schedule authentic remedial ceremonies performed by expert practitioners based on your zyotis consultation.",
                    twitterTitle: "Sacred Pujas & Rituals | ZyotisGuru",
                    twitterDescription: "Book traditional vedic ceremonies recommended by expert zyotis practitioners."
                }
            },
            {
                path: '/rasifal',
                name: 'rasifal',
                component: () => import('@/views/pages/Rasifal.vue'),
                meta: {
                    title: 'Daily & Weekly Rasifal (Horoscope) | ZyotisGuru',
                    description: "Get accurate daily, weekly, and monthly rasifal (horoscope) predictions from ZyotisGuru's expert astrologers. Nepali and English horoscopes for all zodiac signs.",
                    keywords: "nepali rasifal, zyotis predictions, daily horoscope nepal, jyotish rasifal, weekly horoscope, zyotisguru predictions, astro forecast, zodiac predictions",
                    ogTitle: "ZyotisGuru Rasifal - Daily & Weekly Astrological Predictions",
                    ogDescription: "Accurate horoscopes and predictions for all zodiac signs from Nepal's top zyotis experts.",
                    twitterTitle: "ZyotisGuru Rasifal & Horoscopes",
                    twitterDescription: "Daily, weekly and monthly predictions from expert zyotis practitioners."
                }
            },
            {
                path: '/live-astrologers',
                name: 'live-astrologers',
                component: () => import('@/views/pages/LiveAstrologer.vue'),
                meta: {
                    title: 'Live Jyotish Consultations | ZyotisGuru',
                    description: "Connect with ZyotisGuru's experienced astrologers live for instant consultations and guidance. Real-time jyotish advice and predictions from verified experts.",
                    keywords: "live zyotis consultation, online jyotish, instant astrology, live astro chat, zyotisguru live, nepal astrologers online, real-time predictions",
                    ogTitle: "Live Consultations with ZyotisGuru Experts",
                    ogDescription: "Get instant jyotish advice and predictions from Nepal's top zyotis experts through live consultations.",
                    twitterTitle: "ZyotisGuru Live Consultations",
                    twitterDescription: "Connect with expert zyotis practitioners in real-time for immediate guidance."
                }
            },
            {
                path: '/articles',
                name: 'articles',
                component: () => import('@/views/pages/Articles.vue'),
                meta: {
                    title: 'Jyotish Articles & Astrology Insights | ZyotisGuru',
                    description: "Explore in-depth articles and insights about Vedic astrology, jyotish principles, and spiritual guidance from ZyotisGuru's expert astrologers.",
                    keywords: "zyotis articles, jyotish knowledge, astrology blog, vedic astrology guides, zyotisguru insights, spiritual guidance, astro education, nepali astrology",
                    ogTitle: "ZyotisGuru Knowledge Hub - Jyotish Articles & Insights",
                    ogDescription: "In-depth articles on Vedic astrology and zyotis principles from Nepal's expert practitioners.",
                    twitterTitle: "ZyotisGuru Astrology Articles",
                    twitterDescription: "Expert insights and educational content about jyotish and astrology."
                }
            },
            {
                path: '/articles/:slug',
                name: 'article',
                component: () => import('@/views/pages/Article.vue'),
                meta: {
                    title: 'Astrology Insights & Jyotish Knowledge | ZyotisGuru',
                    description: "Expert jyotish knowledge and astrological insights from ZyotisGuru's verified practitioners. Learn about Vedic astrology, remedies, and spiritual practices.",
                    keywords: "zyotis article, jyotish insights, vedic astrology knowledge, zyotisguru blog, astro education, spiritual guidance, astrological principles",
                    ogTitle: "ZyotisGuru Expert Jyotish Insights",
                    ogDescription: "In-depth astrological knowledge from Nepal's top zyotis practitioners and educators.",
                    twitterTitle: "ZyotisGuru Astrology Article",
                    twitterDescription: "Expert zyotis knowledge and spiritual insights from verified practitioners."
                }
            },
            {
                path: '/privacy-policy',
                name: 'privacy-policy',
                component: () => import('@/views/pages/PrivacyPolicy.vue'),
                meta: {
                    title: 'Privacy Policy | ZyotisGuru',
                    description: "ZyotisGuru's privacy policy detailing how we protect your personal information. Nepal's trusted jyotish platform committed to client confidentiality.",
                    keywords: "zyotisguru privacy, jyotish consultation privacy, astrology service terms, zyotis data policy, client confidentiality",
                    ogTitle: "ZyotisGuru Privacy Policy",
                    ogDescription: "How Nepal's premier zyotis platform protects your personal information and ensures confidentiality.",
                    twitterTitle: "ZyotisGuru Privacy Policy",
                    twitterDescription: "Our commitment to protecting your privacy during zyotis consultations."
                }
            },
            {
                path: '/term-conditions',
                name: 'termConditions',
                component: () => import('@/views/pages/TermConditions.vue'),
                meta: {
                    title: 'Terms & Conditions | ZyotisGuru',
                    description: "ZyotisGuru's terms of service for jyotish consultations, astrology readings, and spiritual services. Clear guidelines for Nepal's leading astrology platform.",
                    keywords: "zyotisguru terms, jyotish service conditions, astrology consultation terms, zyotis services agreement",
                    ogTitle: "ZyotisGuru Terms & Conditions",
                    ogDescription: "Terms of service for Nepal's premier zyotis and astrology consultation platform.",
                    twitterTitle: "ZyotisGuru Terms of Service",
                    twitterDescription: "Our service terms for zyotis consultations and astrological services."
                }
            },
            {
                path: '/contact-us',
                name: 'contacts',
                component: () => import('@/views/pages/Contanct.vue'),
                meta: {
                    title: 'Contact ZyotisGuru | Nepal\'s Premier Jyotish Platform',
                    description: "Connect with ZyotisGuru, Nepal's leading jyotish and astrology platform. Reach our team for consultations, support, or business inquiries.",
                    keywords: "contact zyotisguru, jyotish consultation inquiry, astrology service support, zyotis expert contact, nepal astrologer contact",
                    ogTitle: "Contact ZyotisGuru - Nepal's Premier Astrology Platform",
                    ogDescription: "Reach out to Nepal's leading zyotis platform for consultations, support or business inquiries.",
                    twitterTitle: "Contact ZyotisGuru",
                    twitterDescription: "Get in touch with Nepal's premier zyotis and astrology experts."
                }
            },
            {
                path: '/about-us',
                name: 'about',
                component: () => import('@/views/pages/About.vue'),
                meta: {
                    title: 'About ZyotisGuru | Nepal\'s Trusted Jyotish Platform',
                    description: "Learn about ZyotisGuru, Nepal's leading platform connecting clients with verified jyotish experts and astrologers. Our mission, values, and commitment to authentic guidance.",
                    keywords: "about zyotisguru, nepal jyotish platform, astrology service nepal, zyotis experts, vedic astrology platform, nepali astrologer network",
                    ogTitle: "About ZyotisGuru - Nepal's Trusted Astrology Platform",
                    ogDescription: "Discover what makes ZyotisGuru Nepal's premier zyotis and astrology consultation platform.",
                    twitterTitle: "About ZyotisGuru",
                    twitterDescription: "Nepal's trusted platform for authentic zyotis and astrological guidance."
                }
            },
            {
                path: '/call-appointment',
                name: 'call-appointment',
                component: () => import('@/views/pages/call/CallContainer.vue'),
                meta: {
                    title: 'Jyotish Call Consultations | ZyotisGuru',
                    description: "Private phone consultations with ZyotisGuru's expert astrologers. Get personalized jyotish guidance and predictions through convenient call appointments.",
                    keywords: "zyotis call consultation, jyotish phone reading, astrology call appointment, zyotisguru phone consultation, astro call service, nepal astrologer call",
                    ogTitle: "ZyotisGuru Call Consultations - Private Jyotish Guidance",
                    ogDescription: "Schedule private phone consultations with Nepal's top zyotis experts for personalized guidance.",
                    twitterTitle: "ZyotisGuru Phone Consultations",
                    twitterDescription: "Personalized zyotis guidance through convenient call appointments."
                }
            },

        {
            path: '/login',
            name: 'login',
            component: () =>
                import ('@/views/pages/auth/LoginPage.vue'),
            meta: {title: 'Login'}
        },
        {
            path: '/register',
            name: 'register',
            component: () =>
                import ('@/views/pages/auth/RegisterPage.vue'),
            meta: {title: 'Register'}
        },
        {
            path: '/forgot-password',
            name: 'forgot-password',
            component: () =>
                import ('@/views/pages/auth/ForgotPasswordPage.vue'),
            meta: {title: 'Forgot Password'}
        },
        {
            path: '/enter-otp',
            name: 'enter-otp',
            component: () =>
                import ('@/views/pages/auth/EnterOtpPage.vue'),
            meta: {title: 'Enter OTP'}
        },
        {
            path: '/register-otp',
            name: 'register-otp',
            component: () =>
                import ('@/views/pages/auth/RegisterOtp.vue'),
            meta: {title: 'Register OTP'}
        },
        // update-phone
        {
            path: '/update-phone-otp',
            name: 'update-phone-otp',
            component: () =>
                import ('@/views/pages/auth/UpdatePhoneOtp.vue'),
            meta: {title: 'Update Phone OTP'}
        },
        {
            path: '/reset-password',
            name: 'reset-password',
            component: () =>
                import ('@/views/pages/auth/ResetPasswordPage.vue'),
            meta: {title: 'Reset Password'}
        },
        {
            path: '/pages/notfound',
            name: 'notfound',
            component: () =>
                import ('@/views/pages/NotFound.vue'),
            meta: {title: 'Page Not Found'}
        },
        {
            path: '/access',
            name: 'accessDenied',
            component: () =>
                import ('@/views/pages/auth/AccessPage.vue'),
            meta: {title: 'Access Denied'}
        },
        {
            path: '/error',
            name: 'error',
            component: () =>
                import ('@/views/pages/auth/ErrorPage.vue'),
            meta: {title: 'Error'}
        },
        {
            path: '/class',
            name: 'class',
            component: () =>
                import ('@/views/pages/Class.vue'),
            meta: {title: 'Class'}
        },
        {
            path: '/call',
            name: 'call',
            component: () =>
                import ('@/views/pages/Call.vue'),
            meta: {title: 'Call'}
        },
    ]
});

router.beforeEach(async (to, from, next) => {
    const isAuthenticated = !!sessionStorage.getItem('apiToken');

    // SEO Meta Tags Update
    // Default meta tags focused on target keywords
    const defaultTitle = 'ZyotisGuru | #1 Jyotish & Astrology Services in Nepal';
    const defaultDescription = "Nepal's premier Zyotis Guru platform offering expert jyotish consultations, horoscopes & birth chart analysis. Connect with verified astrologers for accurate predictions.";
    const defaultKeywords = "zyotis guru, jyotis, jyotish, jyotis gurus, zyotis, astro, astrologers nepal, astrology nepal";

    // Set title with proper format for SEO
    document.title = to.meta.title ?
        `${to.meta.title}` :
        defaultTitle;

    // Update meta tags if they exist
    const updateMetaTag = (name, content, attribute = 'name') => {
        let meta = document.querySelector(`meta[${attribute}="${name}"]`);
        if (meta) {
            meta.setAttribute('content', content);
        } else {
            meta = document.createElement('meta');
            meta.setAttribute(attribute, name);
            meta.setAttribute('content', content);
            document.head.appendChild(meta);
        }
    };

    // Standard meta tags
    updateMetaTag('description', to.meta.description || defaultDescription);
    updateMetaTag('keywords', to.meta.keywords || defaultKeywords);

    // Set canonical URL
    let canonicalLink = document.querySelector('link[rel="canonical"]');
    if (canonicalLink) {
        canonicalLink.setAttribute('href', `https://zyotisguru.com${to.path}`);
    } else {
        canonicalLink = document.createElement('link');
        canonicalLink.setAttribute('rel', 'canonical');
        canonicalLink.setAttribute('href', `https://zyotisguru.com${to.path}`);
        document.head.appendChild(canonicalLink);
    }

    // Open Graph tags for social sharing
    updateMetaTag('og:title', to.meta.ogTitle || document.title, 'property');
    updateMetaTag('og:description', to.meta.ogDescription || to.meta.description || defaultDescription, 'property');
    updateMetaTag('og:url', `https://zyotisguru.com${to.path}`, 'property');
    updateMetaTag('og:type', 'website', 'property');

    // Twitter Card tags
    updateMetaTag('twitter:title', to.meta.twitterTitle || document.title);
    updateMetaTag('twitter:description', to.meta.twitterDescription || to.meta.description || defaultDescription);
    updateMetaTag('twitter:card', 'summary_large_image');

    // Handle original authentication logic
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!isAuthenticated) {
            next({name: 'login'});
        } else {
            try {
                const response = await api.get('/get-profile', {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('apiToken')}`
                    }
                });

                const data = response.data.result.result;
                const userType = data.user_type;

                const routeWithUserType = to.matched.find(record => record.meta.userType);

                if (routeWithUserType) {
                    if (
                        (Array.isArray(routeWithUserType.meta.userType) && !routeWithUserType.meta.userType.includes(userType)) ||
                        (!Array.isArray(routeWithUserType.meta.userType) && routeWithUserType.meta.userType !== userType)
                    ) {
                        next({name: 'accessDenied'});
                    } else {
                        next();
                    }
                } else {
                    next();
                }
            } catch (error) {
                console.error(error);
                next({name: 'error'});
            }
        }
    } else {
        next();
    }
});
export default router;
