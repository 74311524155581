import axios from 'axios';

// const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;

const api = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    imageBaseUrl: process.env.VUE_APP_IMAGE_BASE_URL,
    // headers: {
    //     'Content-Type': 'application/json',
    // },
    // You can also set default headers here, if needed
    // headers: {
    //   'Content-Type': 'application/json',
    // }
});
console.log(process.env.VUE_APP_API_BASE_URL);

export default api;
