<script setup>
    import TopLoader from './components/TopLoader.vue';
    import { onMounted } from 'vue';
    import { useMessageStore } from './stores/messageStore';
    const messageStore = useMessageStore();

    onMounted(() => {
        messageStore.fetchUser();
    })
</script>

<template>
    <div id="app">
    <TopLoader />
    <router-view />
    </div>
</template>

<style scoped></style>
